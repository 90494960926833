import * as React from "react";
import { Typography, Stack, Grid, Box, Button } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// components
import Section from "../utils/section";
import Link from "../utils/link";

const Fragranze = () => {
    return (
        <Section>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <StaticImage imgStyle={{ borderRadius: 16 }} src="../images/fragranze.jpg" alt="Fragranza salviette monouso" layout="fullWidth" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="h2">
                            Fragranze salviette monouso
                        </Typography>
                        <Typography>
                            Nel corso degli anni abbiamo selezionato, studiato e sviluppato 6 tipologie di <strong>fragranze</strong> ideali per ogni tipo di
                            realtà, dal ristorante di pesce al Bed&Breakfast. Puoi scegliere per la tua attività la fragranza che più si addice tra le seguenti
                            profumazioni:
                        </Typography>
                        <ul>
                            <li>Limone</li>
                            <li>Lime e cannella</li>
                            <li>Mojito</li>
                            <li>Coloniale</li>
                            <li>Tè verde</li>
                            <li>Sweet fruit</li>
                        </ul>
                        <Typography>
                            Le nostre salviettine monouso sono state progettate per soddisfare diversi requisiti tra cui l'
                            <strong>eliminazione degli odori</strong> poco gradevoli dalla pelle come quelli del pesce, ed allo stesso tempo per{" "}
                            <strong>rinfrescare le mani</strong> alla fine del pasto.
                        </Typography>
                        <Box>
                            <Link type="internal" href="/salviette-monouso" title="Scopri di più sulle salviette">
                                <Button style={{ padding: 8, marginLeft: -8 }} size="large" variant="text" color="info" endIcon={<ArrowForwardIcon />}>
                                    Scopri di più
                                </Button>
                            </Link>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Section>
    );
};

export default Fragranze;
